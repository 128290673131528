import i18n from "@/libs/i18n";

const statues = [
    {
        id: '-1',
        title: i18n.t('status.pending'),
        color: 'yellow'
    },
    {
        id: 1,
        title: i18n.t('status.preparing'),
        color: 'blue'
    },
    {
        id: 2,
        title: i18n.t('status.canceled'),
        color: 'red'
    },
    {
        id: 3,
        title: i18n.t('status.scheduled'),
        color: 'green'
    },
    {
        id: 4,
        title: i18n.t('status.delivering'),
        color: 'purple'
    },

    {
        id: 5,
        title: i18n.t('status.complete'),
        color: 'green'
    },
    {
        id: 6,
        title: i18n.t('status.waiting'),
        color: 'yellow'
    },
    {
        id: 7,
        title: i18n.t('status.confirmation'),
        color: 'yellow'
    },


];

function filterStatus(id) {
    return statues.find((item) => item.id === id);
}

export {statues, filterStatus};
