import i18n from "@/libs/i18n";





const LogActivity = [
    {
        id: 1,
        name: i18n.t('logs.activity.create'),
    },
    {
        id: 2,
        name: i18n.t('logs.activity.update'),
    },
    {
        id: 3,
        name: i18n.t('logs.activity.delete'),
    },
    {
        id: 4,
        name: i18n.t('logs.activity.toggle'),
    },

    {
        id: 5,
        name: i18n.t('logs.activity.reorder'),
    },

    {
        id: 6,
        name: i18n.t('logs.activity.export'),
    },
    {
        id: 7,
        name: i18n.t('logs.activity.import'),
    },
    {
        id: 8,
        name: i18n.t('logs.activity.resetPassword'),
    },
    {
        id: 9,
        name: i18n.t('logs.activity.sendNotification'),
    },
    {
        id: 10,
        name: i18n.t('logs.activity.order'),
    },
    {
        id: 11,
        name: i18n.t('logs.activity.changeOrderStatus'),
    }

];

function filterLogActivity(id) {
    return LogActivity.find((item) => item.id === id);
}

export {LogActivity, filterLogActivity};
